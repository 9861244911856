<template>
  <div
    :class="preloaderClasses"
    v-if="preloadState"
  >
    <div>
      <img :src="require('../assets/img/logo_badge.png')" class="preloader__logo">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Preloader',
  props: {
    isLoading: Boolean,
    isGlobal: Boolean,
  },
  computed: {
    preloaderClasses() {
      if (this.isGlobal) return 'preloader preloader--global';
      return 'preloader';
    },
    preloadState() {
      // console.log('this.$store.getters.isPreloading', this.$store.getters.isPreloading);
      return this.$store.getters.isPreloading;
    },
  },
};
</script>
